import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import moment from 'moment';
@Injectable({
    providedIn: 'root'
  })
export class AuthService {
  constructor(public jwtHelper: JwtHelperService,
    private _router:Router) {}
  // ...
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    const loggedInTime = localStorage.getItem('loggedInTime');
    let noOfDays = 1;
    if(loggedInTime){
      noOfDays = moment(new Date(),'YYYY-MM-DD').diff(moment(new Date(loggedInTime),'YYYY-MM-DD'),'days');
    }
    if(noOfDays == 0){
      return !this.jwtHelper.isTokenExpired(token);
    }else{
      localStorage.removeItem('currentUser');
      this._router.navigate(['./'])
      return false;
    }
  }
}