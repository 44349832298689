import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit{

  constructor(private _router:Router, private _aROute:ActivatedRoute) {
    
   }

  ngOnInit(): void {
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler($event) {
    window.location.replace('/');
    this._router.navigate(['/'])
    return false;
  }
  retry(){
      this._router.navigate(['./'])
  }

}
