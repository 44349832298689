import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './modules/admin-layout/auth/auth-guard.service';

import { AdminLayoutComponent } from './modules/admin-layout/admin-layout.component';
import { LoginComponent } from './components/login/login.component';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path:'',
    redirectTo:'accounts',
    pathMatch:'full'
  },
  {
    path: '',
    loadChildren: './modules/admin-layout/admin-layout.module#AdminLayoutModule',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/error'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
