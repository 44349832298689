import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
  } from '@angular/common/http';
  import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorUtility implements HttpInterceptor {
    constructor(private _route:Router){}
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let token = localStorage.getItem('token');
      const keysToRemove = ['Enterd_By','enterdBy', 'createdAt','updatedAt'];
      if (httpRequest.body && typeof httpRequest.body === 'object') {
        // Clone the body and remove specified keys
        const modifiedBody = { ...httpRequest.body };
        keysToRemove.forEach((key) => delete modifiedBody[key]);
        if(modifiedBody['data']){
          keysToRemove.forEach((key) => delete modifiedBody['data'][key]);
        }
        // Clone the request with the modified body
        if(modifiedBody['data']){
          keysToRemove.forEach((key) => delete modifiedBody['data'][key]);
        }
        httpRequest = httpRequest.clone({
          body: modifiedBody,
        });
      }
      if (token) {
        token =token.toString().replace('"','');
        token =token.toString().replace('"','');
        httpRequest = httpRequest.clone({
              setHeaders: { Authorization: `Bearer ${token}` }
          });
      }else{
        this._route.navigate(['login'])
      }
      return next.handle(httpRequest)
    }
}